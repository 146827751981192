@import "app.less";

:root {
  --bg-color-main: white;
  --text-color-primary: #293742; //rgba(0, 0, 0, 0.8);
  --ui-font: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif;
  --code-font: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  --space-factor: 0.25rem;
  /* Mersen
  blue-gray: #61849c rgba(97, 132, 156, 1)
  orange: #f46a25 rgba(244, 106, 37, 1)
  */
  --mersen-gray: rgba(81, 116, 133, 1);
  --mersen-orange: #e85215; //rgba(232, 78, 15, 1);
  --mersen-orange-hover: #ff7f32;
  --mersen-dark-gray: #2e3c45;
  --mersen-dark-gray-hover: #60849c;
  --mersen-light-background-color: #f8f8f9;
  --mersen-heading-text-color: #2c3a43;
  --mersen-text-color: #293742;

  --app-border-color: #d9d9d9; //#f0f0f1;
  --app-background-color: white;
  --app-header-top-border-color: var(--mersen-orange);
  --app-header-bottom-border-color: var(--mersen-light-background-color);
  --app-header-background-color: #ffffff;
  --app-side-background-color: var(--mersen-light-background-color);
  /* AWS Amplify */
  --amplify-primary-color: var(--mersen-orange);
  --amplify-primary-tint: var(--mersen-orange-hover);
  --amplify-primary-shade: #e0573e;
}

// remove Amplify authenticator modal background
amplify-authenticator {
  --container-height: auto;
  --box-shadow: none;
  --background-color: transparent;
}

// less layout variables
@header-height: 72px;
@footer-height: 0;
@bar-height: 50px;
@side-panel-width: 280px;
@side-panel-toolbar-width: 40px;

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  font-family: var(--ui-font);
  color: var(--text-color-primary);
  background: var(--bg-color-main);
  user-select: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}


@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);