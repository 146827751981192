.fin-grid-container {
  display: grid;
  grid-template-columns: 25px auto repeat(8, 10%);
  row-gap: 2px;
  column-gap: 2px;
  text-align: center;
  margin: 16px;
  color: #000;

  .item-type {
    background-color: #b4c6e7;
    font-weight: 600;
    &:empty {
      background-color: transparent;
    }
  }

  .item-thickness,
  .item-pitch {
    background-color: #d9e2f2;
    &:empty {
      background-color: transparent;
    }
  }

  .item-height {
    &.estimate {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      background-color: #e4e4e4;
      opacity: 0.8;
      .item {
        width: 100%;
        border: 2px solid transparent;
        &:hover {
          opacity: 1;
          font-weight: 600;
        }
      }
      .invalid {
        background-color: #fdd8d5;
      }
      .lowpressure {
        background-color: #ffe1c9;
        border: 2px solid #fe9201;
        font-weight: 600;
        opacity: 1;
      }
      .suggested {
        background-color: #dfc9ef;
        border: 2px solid #7030a0;
        font-weight: 600;
        opacity: 1;
      }
      .custom {
        background-color: #b4c6e7;
        border: 2px solid #305496;
        font-weight: 600;
        opacity: 1;
      }
      &:hover {
        cursor: pointer;
      }
    }

    // the first item in row 0, col 0 is the vertical "Fin Height" label
    &.row-0.column-0 {
      grid-row-start: 4;
      grid-row-end: 15;
      background-color: #b4c6e7;
      writing-mode: vertical-lr;
      justify-self: center;
      align-self: stretch;
      font-weight: 600;
      transform: rotate(180deg);
      &:after {
        content: '';
      }
    }

    // specifically handle col row 0, col 1 due to height label
    &.column-0,
    &.row-0.column-1 {
      background-color: #d9e2f2;
      opacity: 1;
      &:hover {
        cursor: default;
      }
      &:after {
        content: ' mm';
        font-size: 0.7rem;
      }
    }
  }
}

.fin-selector {
  .fin-selector-bar {
    display: flex;
    flex-direction: row;
    .segment {
      background-color: #f2f2f2;
      margin: 3px 0;
      &.first {
        background-color: #9854a1;
        width: 50%;
      }
      &.second {
        background-color: #6b7ebb;
        width: 30%;
      }
      &.third {
        width: 20%;
      }
    }
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);