.project-bar-wrapper {
  display: grid;
  grid-template-columns: auto 1fr 20fr;
  place-items: center;
  grid-gap: 16px;
  height: 100%;
  width: 100%;

  .title {
    justify-self: start;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .status {
    font-style: italic;
    font-size: 0.8rem;
  }

  .actions {
    justify-self: end;
    margin:0 0 4px 0;
    button {
      font-size: 14px;
    }
  }

}
@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);