.boundary-conditions {

  h3 {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    font-size: 16px;
    margin: 16px 0 8px;
  }

  .dimension-image {
    margin: 8px;
    text-align: center;
  }

  .measurement-input-width {
    max-width: 172px;
  }
}
@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);