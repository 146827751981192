.control-wrapper-container {
  display: grid;
  grid-template-columns: auto 695px auto;

  &.extrusion {
   grid-template-columns: auto 845px auto;
  }

  .control-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 32px;

    .control-group {
      display: grid;
      grid-template-rows: 1fr 1fr;
      place-items: center;
      margin: 0 16px;

      label {
        display:inline-block;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 600;
        &.extrusion-id {
          text-align: center;
          width: 160px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          span {
            font-weight: normal;
          }
        }
      }
    }
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);