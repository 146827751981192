.pipe-grid-container {
  display: grid;
  grid-template-columns: 25px auto repeat(2, 20%);
  row-gap: 2px;
  column-gap: 2px;
  text-align: center;
  margin: 16px;
  color: #000;

  .item {
    background-color: #e4e4e4;
    &:empty:after {
      content: '\25ef';
      font-size: 0.7rem;
    }
    &:not(.selected):empty:hover {
      background-color: #ddd;
      cursor: pointer;
      opacity: 1;
      &:after {
        content: '\2713';
      }
    }

    &.invalid {
      background-color: #fdd8d5;
      pointer-events: none;
      &:after {
        content: '\2715';
        font-size: 0.7rem;
      }
    }

    &.selected {
      background-color: #b4e7c7;
      &:empty:after {
        content: '\2713';
        font-size: 0.7rem;
        font-weight: 700;
      }
    }
  }

  .row-0 {
    background-color: #b4c6e7;
    font-weight: 600;
  }

  // the first item in row 0, col 0 is the vertical "Fin Height" label
  .row-0.column-0 {
    grid-row-start: 2;
    grid-row-end: 6;
    background-color: #b4c6e7;
    writing-mode: vertical-lr;
    justify-self: center;
    align-self: stretch;
    font-weight: 600;
    transform: rotate(180deg);
    &:after {
      content: '';
    }
  }

  // specifically handle col row 0, col 1 due to height label
  .row-0.column-1 {
    background-color: transparent;
  }

  .column-1 {
    background-color: #d9e2f2;
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);