.fin-direction {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80px;

  .arrow {
    width: 0;
    height: 0;
    margin: auto;

    &.arrow-up {
      border-left: 36px solid transparent;
      border-right: 36px solid transparent;
      border-bottom: 36px solid #eee;
    }

    &.arrow-down {
      border-left: 36px solid transparent;
      border-right: 36px solid transparent;
      border-top: 36px solid #eee;
    }
  }

  .arrow-body {
    background-color: #eee;
    margin: 0 auto;
    flex-grow: 1;
    width: 36px;
    display: flex;

    .label {
      .text {
        width: 1rem; // center in Safari
        color: rgba(0,0,0,0.4);
        font-weight: 600;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
      }
    }
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);