.coldplate-layout {
  .coldplate-top-edge {
    height: 10px;
    transform: skew(-50deg);
    border: 2px solid #d6d6d6;
    background: #e6e6e6;
    margin-left: 5px;
    &.disabled {
      background: rgb(238, 238, 238);
    }
  }

  .coldplate-side-edge {
    width: 11px;
    transform: skewY(-40deg);
    border: 2px solid #d6d6d6;
    margin-top: -6px;
    border-left: none;
    background: #e6e6e6;
    &.disabled {
      background: rgb(238, 238, 238);
    }
  }

  .coldplate {
    position: relative;
    padding: 0;
    background-color: #eee;

    &.grid {
      background-image: linear-gradient(to right, #c2c2c2 1px, transparent 1px);
      background-position: -1px -1px;
    }

    &.active:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-50%, 50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: rgb(135, 135, 135);
    }

    &.selected.active:before {
      background: #f46a25;
    }

    .coldplate-popover-wrapper {
      position: absolute;
      top: -25px;
      right: -5px;
      padding: 2px;
      cursor: pointer;
      font-size: 1.25rem;
      line-height: 1rem;
      visibility: hidden;
      z-index: 10;
    }

    &:hover .coldplate-popover-wrapper,
    &.selected .coldplate-popover-wrapper {
      visibility: visible;
    }
  }

  .flow-labels {
    display: grid;
    grid-template-columns: 48px 32px 48px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #878787;
    font-size: 14px;
    font-weight: 600;

    .flow-inlet {
      display: inline-block;
      color: #1890ff;
      transform: rotate(180deg);
      &:before {
        content: '\21e9';
        font-size: 2em;
        font-weight: normal;
      }
    }
    .flow-outlet {
      display: inline-block;
      color: #ff4d4f;
      transform: rotate(180deg);
      &:before {
        content: '\21e9';
        font-size: 2em;
        font-weight: normal;
      }
    }
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);