.simulation-images {
  display: grid;
  grid-template-columns: 4fr 3fr 3fr;
  place-items: center;
  grid-gap: 16px;
}

.simulation-images-text {
  display: grid;
  grid-template-columns: 4fr 3fr 3fr;
  grid-gap: 16px;
  & > div {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    text-align: center;
    border-bottom: 5px solid var(--app-header-bottom-border-color);
  }
}

.simulation-result-files {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.labeled-value-wrapper {
  display: grid;
  grid-template-columns: 180px 80px;
  grid-gap: 8px;
  .value-wrapper {
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);