.heat-source {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid #678289;
  background: #b2ddeb;
  z-index: 0;
  font-size: 14px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: inherit;
  &:hover {
    background: #b8e5f5;
  }

  &.active {
    border: 2px solid #f46a25;
    background: #f1d4cb;
    cursor: move;
    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      transform: translate(-50%, 50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #f46a25;
    }
    &:hover {
      background: #f1d4cb;
    }
    .heat-source-popover-wrapper {
      visibility: visible;
    }
  }

  &.invalid {
    border: 2px solid #cc6666;
    background: #ffccc7;
  }

  &.disabled {
    opacity: 0.5;
    background: #e2e2e2;
    border: 2px solid #a2a2a2;
  }

  .heat-source-content {
    width: 100%;
    text-align: center;
    .label {
      font-weight: bold;
      line-height: 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .power {
      font-variant: all-small-caps;
      line-height: 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .heat-source-popover-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    visibility: hidden;
  }

  &:hover .heat-source-popover-wrapper {
    visibility: visible;
  }
}

.heat-source-popover {
  label {
    color: rgba(0, 0, 0, 0.6);
    &.title {
      font-weight: 600;
      line-height: 1.65rem;
    }
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);