.pipe {
  width: 100%;
  height: 100%;
  position: absolute;
  color: #82a9b1;
  border: 2px solid #678289;
  background: #b2ddeb;
  z-index: 0;
  font-size: 14px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: inherit;
  &:hover {
    background: #b8e5f5;
  }
  &:after {
    transform: rotate(180deg);
    content: '\21e9';
    font-size: 1.25em;
  }

  &.serpentine {
    &:after {
      margin-top: -40px;
    }
  }

  &.inlet {
    &:after {
      transform: rotate(180deg);
      content: '\21e9';
      font-size: 1.25em;
    }
  }

  &.flow-down:after {
    transform: rotate(0);
    content: '\21e9';
    font-size: 1.25em;
    margin-top: 80px;
  }

  &.active {
    color: #f46a25;
    border: 2px solid #f46a25;
    background: #f1d4cb;
    cursor: move;
    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #f46a25;
    }
    &:hover {
      background: #f1d4cb;
    }
    .pipe-popover-wrapper {
      visibility: visible;
    }
  }

  &.invalid {
    border: 2px solid #cc6666;
    background: #ffccc7;
  }

  &.disabled {
    opacity: 0.5;
    background: #e2e2e2;
    border: 2px solid #a2a2a2;
  }

  .label {
    position: absolute;
    bottom: 4px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    line-height: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .pipe-inlet {
    position: absolute;
    bottom: -40px;
    text-align: center;
    color: #1890ff;
  }

  &.inlet {
    .pipe-inlet {
      transform: rotate(180deg);
      &:after {
        content: '\21e9';
        font-size: 2em;
      }
    }
  }

  .pipe-outlet {
    position: absolute;
    top: -40px;
    text-align: center;
    color: #ff4d4f;
  }


  &.outlet {
    .pipe-outlet {
      transform: rotate(180deg);
      &:after {
        content: '\21e9';
        font-size: 2em;
      }
    }
  }

  &.flow-down.outlet {
    .pipe-outlet {
      top: auto;
      bottom: -40px;
      transform: rotate(0deg);
      &:after {
        content: '\21e9';
        font-size: 2em;
      }
    }
  }

  .pipe-popover-wrapper {
    position: absolute;
    top: 0;
    right: -5px;
    padding: 2px;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    visibility: hidden;
  }

  &:hover .pipe-popover-wrapper {
    visibility: visible;
  }
}

.pipe-popover {
  label {
    color: rgba(0, 0, 0, 0.6);
    &.title {
      font-weight: 600;
      line-height: 1.65rem;
    }
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);