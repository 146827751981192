.fin-option-buttons-wrapper {
  display: grid;
  grid-template-columns: 20% 20% 20%;
  column-gap: 5%;
  text-align: center;
  margin: 0 32px;
  justify-content: center;
  min-height: 110px;

  button {
    font-weight: bold;
    &:hover:not(.disabled) {
      cursor: pointer;
    }
  }

  .button-suggested {
    background-color: #dfc9ef;
    border: 2px solid #7030a0;
    opacity: 0.7;
    &.active,
    &:focus,
    &:hover {
      opacity: 1;
    }
    &.disabled {
      opacity: 0.6;
    }
    &.active {
      box-shadow: 0 0 8px #b5b5b5;
    }
  }
  .button-lowpressure {
    background-color: #ffe1c9;
    border: 2px solid #fe9201;
    opacity: 0.7;
    &.active,
    &:focus,
    &:hover {
      opacity: 1;
    }
    &.disabled {
      opacity: 0.6;
    }
    &.active {
      box-shadow: 0 0 8px #b5b5b5;
    }
  }
  .button-custom {
    background-color: #b4c6e7;
    border: 2px solid #305496;
    opacity: 0.7;
    &.active,
    &:focus,
    &:hover {
      opacity: 1;
    }
    &.disabled {
      opacity: 0.6;
    }
    &.active {
      box-shadow: 0 0 8px #b5b5b5;
    }
  }

  .button-card {
    position: relative;
    color : #474747;
    .title {
      font-size: 1rem;
    }

    .description {
      font-weight: normal;
      font-size: 0.8rem;
      font-style: italic;
    }

    .type {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }

    .length {
      font-size: 1.2rem;
      font-weight: normal;
    }

    .tooltip {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);