.extrusion-modal-content .ant-modal-body {
  padding: 0;
  height: 480px;
  overflow: hidden;
  overflow-y: auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;

  img {
    opacity: 0.6;
  }

  .label {
    font-weight: bold;
    color: #888;
    margin: 0 32px;
    background-color: var(--mersen-light-background-color);
  }

  .selected {
    img {
      opacity: 0.95;
    }
    .label {
      color: var(--mersen-orange);
      background-color: #fdeae1;
    }
  }

  .no-extrusion-text {
    position: absolute;
    top: 40%;
    left: calc(50% - 110px);
    width: 220px;
    text-align: center;
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);