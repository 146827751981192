.start-view.content {

  .banner {
    display: grid;
    grid-template-columns: repeat(5, auto);
    height: 180px;
    padding: 4px;
    background-color: var(--mersen-light-background-color);
    margin: -16px 0 16px;
    grid-column-gap: 4px;

    .banner-image {
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
    }
  }

  .text-block {
    margin: 8px;
  }

  .logo {
    margin-bottom: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    img {
      max-width: 420px;
    }
  }

  .promo-text {
    display: grid;
    grid-template-columns: repeat(5, auto);
    column-gap: 8px;
    text-transform: uppercase;
    color: var(--mersen-orange);
    white-space: nowrap;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .instruction-text {
    font-size: 1rem;
    line-height: 1.5rem;
    background: var(--mersen-light-background-color);
    margin-top: 32px;
    padding: 16px 16px 4px;
    span {
      color: var(--mersen-orange);
    }
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);