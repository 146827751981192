.bar-chart-wrapper {
  display: grid;
  grid-template-columns: 15% auto 15%;
  margin: 32px;
  height: 16px;

  .segment-title {
    align-self: center;
    padding-right: 16px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .segment-label {
    align-self: center;
    padding-left: 16px;
    font-style: italic;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .segment-bar-container {
    position: relative;
    display: grid;
    grid-template-columns: 0 100fr;
    background-color: #ededed;
    z-index: 10;

    .segment-bar-value {
      background-color: #9150c7;
    }

    .segment-bar-marker {
      position: absolute;
      left: 0;
      background-color: #0070c0;
      width: 6px;
      height: 28px;
      margin: -6px 0 -6px -3px;
      z-index: 100;
    }

    .segment-bar-max-marker {
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 12px solid #e80309;
      margin-top: -12px;
      margin-left: -8px;
      color: #c00;
      font-size: 14px;
      font-variant: all-small-caps;
      &::after {
        position: absolute;
        top: -18px;
        left: 10px;
        content: 'Max';
      }
    }
  }
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);