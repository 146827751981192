.arrow {
  margin: auto;
  width: 0;
  height: 0;
}

.arrow-up {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #eee;
}

.arrow-down {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #eee;
}

.arrow-left {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #eee;
}

.arrow-right {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #eee;
}

.dashed-line-vertical {
  border-left: 5px solid #eee;
  flex-grow: 1;
  height: 0;
  margin-left: auto;
  margin-right: auto;
}

.dashed-line-horizontal {
  border-bottom: 5px solid #eee;
  flex-grow: 1;
  height: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.label {
  margin: auto;
  text-align: center;
}

@mersen-gray: rgba(97, 132, 156, 1);@mersen-orange: rgba(244, 106, 37, 1);@primary-color: @mersen-orange;@heading-color: rgba(0, 0, 0, 0.6);@text-color: rgba(0, 0, 0, 0.8);@text-color-secondary: rgba(0, 0, 0, 0.9);