// easy to namespace everything by wrapping in class .orx { ... }
#suspense-spinner:not(:required) {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: @header-height;
  font-size: 24px;
  border-top: 4px solid var(--app-header-top-border-color);
  border-bottom: 8px solid var(--app-header-bottom-border-color);
  background-color: var(--app-header-background-color);

  .content {
    display: grid;
    grid-template-columns: 1fr 50fr 1fr;
    grid-column-gap: 8px;
    height: 100%;
    margin: -2px 16px 0;
    place-items: center;

    .logo {
      justify-self: start;
      button {
        padding: 0;
        img {
          margin-right: 16px;
        }
      }
    }

    .sign-in {
      display: grid;
      grid-template-columns: repeat(3, auto);
      align-items: center;
      justify-self: end;
      margin: 0 0 4px 0;
    }
  }
}

.bar {
  position: absolute;
  top: @header-height;
  right: 0;
  left: 0;
  //height: @bar-height;
  border-bottom: 1px solid var(--app-border-color);
}

.main {
  position: fixed;
  top: @header-height;
  right: 0;
  bottom: @footer-height;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  background: var(--app-background-color);
  transition: all 250ms ease-in-out;

  &.has-bar {
    top: @header-height + @bar-height;
  }

  .content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1440px 1fr;
  }

  // allow space a bottom for validation messages
  .content {
    padding: 1rem 1rem 4rem 1rem;
  }
}

.heading {
  background-color: var(--app-header-bottom-border-color);
  padding: 4px 12px 1px;
  margin: 16px 0 8px;
  font-size: 1.125rem;
  font-weight: 700;
  border-bottom: 3px solid var(--app-border-color);
}

.section-heading {
  background-color: var(--app-header-bottom-border-color);
  padding: 4px 12px;
  margin: 16px 0 8px;
  font-size: 1rem;
  font-weight: 500;
}

.section-wrapper {
  section {
    margin: 0 12px;
  }
}

.number-input-wrapper {
  position: relative;
  .number-input {
    display: grid;
    grid-template-columns: auto 1fr;
    .number-input-label {
      margin: 8px 0 4px;
      grid-column-start: span 2;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    //.value-input { }
    //.units-select { }
  }

  .error-message {
    z-index: 10;
    display: none;
    position: absolute;
    width: 190px;
    //top: 35px;
    left: 0;
    right: 0;
    box-shadow: 0 7px 15px #ccc;
  }

  &.error {
    .number-input {
      .value-input input {
        color: #c00;
      }
    }
    .error-message {
      display: block;
    }
  }
}

// overwrite ant style
.number-input-wrapper.error .number-input .value-input input {
  background: #fff2f0;
}

// used in help panel
.styled-table {
  border-collapse: collapse;
  margin: 24px 0;
  font-family: sans-serif;
  width: 100%;
  thead tr {
    border-bottom: 2px solid #f2f2f2;
  }
  tbody tr:last-child {
    border-bottom: 2px solid #f2f2f2;
  }
}

.help-drawer-content {
  h2 {
    margin-top: 32px;
  }
}
